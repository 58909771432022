import { em } from 'polished'

/*

    Examples:

    const media = Media({
        sm: 320,
        md: 768,
        lg: 1240
    });

    ${media.min('lg')} {
        width: 150px;
    }

    ${media.between('sm', 'lg')} {
        width: 200px;
    }

    ${media.only('md')} {
        background-color: green;
    }

*/

const breakpointNotFoundError = breakpoint => `Breakpoint '${breakpoint}' not found`

export class Media {
  constructor(sizes) {
    this.sizes = {
      xs: 0,
      sm: 321,
      md: 480,
      lg: 769,
      xl: 1025,
      xxl: 1680,
      ...sizes,
    }
  }

  _isBreakpoint = breakpoint => {
    return !!this.sizes[breakpoint] || this.sizes[breakpoint] === 0
  }

  _next(breakpoint) {
    const keys = Object.keys(this.sizes)
    const index = keys.indexOf(breakpoint)
    return keys[index + 1]
  }

  min(breakpoint) {
    if (!this._isBreakpoint(breakpoint)) {
      throw new Error(breakpointNotFoundError(breakpoint))
    }

    return `@media (min-width: ${em(this.sizes[breakpoint])})`
  }

  // The max-width value is calculated as the next breakpoint less 0.02px.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug
  // in Safari. See https://bugs.webkit.org/show_bug.cgi?id=178261
  max(breakpoint) {
    if (!this._isBreakpoint(breakpoint)) {
      throw new Error(breakpointNotFoundError(breakpoint))
    }

    const nextBreakpoint = this._next(breakpoint)

    if (!this._isBreakpoint(nextBreakpoint)) {
      throw new Error(breakpoint)
    }

    return `@media (max-width: ${em(this.sizes[nextBreakpoint] - 0.02)})`
  }

  between(min, max) {
    if (!this._isBreakpoint(min)) {
      throw new Error(breakpointNotFoundError(min))
    }

    if (!this._isBreakpoint(max)) {
      throw new Error(breakpointNotFoundError(max))
    }

    return `@media (min-width: ${em(this.sizes[min])}) and
			(max-width: ${em(this.sizes[max] - 0.02)})`
  }

  only(breakpoint) {
    const nextBreakpoint = this._next(breakpoint)

    return this._isBreakpoint(nextBreakpoint) ? this.between(breakpoint, nextBreakpoint) : this.up(breakpoint)
  }
}

const MediaWithSizes = sizes => new Media(sizes)

export default MediaWithSizes
