import React, { useCallback, useLayoutEffect, Fragment } from 'react'

import Navigation from './Navigation'
import Button from './common/Button'
import Social from '../components/Social'
import Spacer from './common/Spacer'
import { UpArrow } from './common/Icons'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { motion, AnimatePresence } from 'framer-motion'
import { useAppContext } from '../context/AppContext'
import { LockBodyScroll } from '../hooks/useLockBodyScroll'

const transition = { duration: 0.3, ease: 'easeOut' }

const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

function Menu({ socialLinks, ...props }) {
  const {
    menu: { menuOpen, toggleMenu },
    media: { isMedium },
  } = useAppContext()

  const handleClick = useCallback(() => {
    toggleMenu()
  }, [toggleMenu])

  useLayoutEffect(() => {
    if (isMedium && menuOpen) toggleMenu()
  }, [isMedium, menuOpen, toggleMenu])

  return (
    <AnimatePresence>
      {menuOpen && (
        <>
          <LockBodyScroll />

          <Container
            initial={{ opacity: 0, clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)' }}
            animate={{
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              opacity: 1,
              transition: {
                ...transition,
                delayChildren: 1,
                staggerChildren: 0.1,
              },
            }}
            exit={{
              opacity: 0,
              clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
              transition: {
                ...transition,
                ease: 'easeIn',
              },
            }}
          >
            <Flex>
              <div>
                <Navigation light isIndex {...props} />
                <Spacer y={0.5} />
                {!!props.links && props.links.length && (
                  <Links>
                    {props.links.map(({ title, externalUrl, page }, i) => {
                      const slug = page?.slug
                      const link = slug
                        ? { to: `/${slug}` }
                        : {
                            href: externalUrl,
                            target: '_blank',
                            rel: 'noreferrer',
                          }
                      return (
                        <Fragment key={title}>
                          <motion.div variants={fade}>
                            <LinkButton preset="menu" onClick={handleClick} {...link}>
                              {title}
                              {!slug && <UpArrow color="#fff" size={36} />}
                            </LinkButton>
                          </motion.div>
                          {i !== props.links.length - 1 && <Spacer y={0.5} />}
                        </Fragment>
                      )
                    })}
                  </Links>
                )}
              </div>

              {!!socialLinks && socialLinks.length && (
                <SocialContainer variants={fade}>
                  <Social light socialLinks={socialLinks} />
                </SocialContainer>
              )}
            </Flex>
          </Container>
        </>
      )}
    </AnimatePresence>
  )
}

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${theme.color.primary};

    z-index: 9999999;
  `};
`

const LinkButton = styled(Button)`
  svg {
    transform: translateY(${rem(9)});
    margin-left: ${rem(1)};
  }
`

const Flex = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${rem(20)} 0;

  a {
    display: block;
  }
`

const SocialContainer = styled(motion.div)`
  ${({ theme }) => css`
    padding: 0 ${rem(20)} ${rem(20)};

    ${theme.media.min('sm')} {
      padding: 0 ${rem(30)} ${rem(30)};
    }
  `};
`

export default Menu
