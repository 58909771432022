import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const setSize = ({ hide, height, width, theme, x, y }) => css`
  flex-shrink: 0;

  ${hide &&
  css`
    display: none;
  `};

  ${x &&
  css`
    width: ${theme.space(x)};
  `};

  ${y &&
  css`
    height: ${theme.space(y)};
  `};

  ${height &&
  css`
    height: ${rem(height)};
  `};

  ${width &&
  css`
    width: ${rem(width)};
  `};
`

const Spacer = styled.div(
  ({ flex, height, hide, width, x, y, xs, sm, md, lg, xl, test, theme }) => css`
    pointer-events: none;
    user-select: none;

    ${setSize({ height, hide, width, x, y, theme })};

    ${flex &&
    css`
      flex: 1;
    `};

    /* Media queries */

    ${xs &&
    css`
      ${theme.media.min('xs')} {
        ${setSize({ ...xs, theme })};
      }
    `}

    ${sm &&
    css`
      ${theme.media.min('sm')} {
        ${setSize({ ...sm, theme })};
      }
    `}

    ${md &&
    css`
      ${theme.media.min('md')} {
        ${setSize({ ...md, theme })};
      }
    `}

    ${lg &&
    css`
      ${theme.media.min('lg')} {
        ${setSize({ ...lg, theme })};
      }
    `}

    ${xl &&
    css`
      ${theme.media.min('xl')} {
        ${setSize({ ...xl, theme })};
      }
    `}

    ${test &&
    css`
      border: 1px solid red;
    `};
  `
)

const shape = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  hide: PropTypes.bool,
  width: PropTypes.number,
}

Spacer.propTypes = {
  ...shape,
  xs: PropTypes.shape({
    ...shape,
  }),
  sm: PropTypes.shape({
    ...shape,
  }),
  md: PropTypes.shape({
    ...shape,
  }),
  lg: PropTypes.shape({
    ...shape,
  }),
  xl: PropTypes.shape({
    ...shape,
  }),
  test: PropTypes.bool,
}

export default Spacer
