import React from 'react'

import { SANS_SERIF } from '../constants/type'

import { AppContext } from '../context/AppContext'

import { createGlobalStyle } from 'styled-components/macro'
import { css } from 'styled-components/macro'
import vars from '../style/vars'

const GlobalStyle = createGlobalStyle`
  ${({ theme, showFocus }) => css`
    ${vars};

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }

    html,
    body {
      height: 100%;
    }

    body {
      display: flex;
      flex-direction: column;

      line-height: 1;
      margin: 0;
      padding: 0;
      background-color: var(--color-background);

      font-size: 100%;

      font-family: ${SANS_SERIF}, sans-serif;
    }

    ol,
    ul {
      list-style: none;
    }

    blockquote,
    q {
      quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: box-shadow 0.1s ease-out;

      &:focus {
        outline: none;
        box-shadow: none;

        ${showFocus &&
          css`
            box-shadow: inset 0px 0px 5px -2px ${theme.color.blue}, 0px 0px 0px 2px ${theme.color.blue};
          `};
      }
    }

    input {
      transition: box-shadow 0.1s ease-out;

      &:focus {
        ${showFocus &&
          css`
            box-shadow: inset 0px 0px 5px -2px ${theme.color.blue}, 0px 0px 0px 2px ${theme.color.blue};
          `}
      }
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
    }

    img {
      font-size: 0.8rem;
      font-family: ${SANS_SERIF}, sans-serif;
    }

    #root {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    #portal {
      top: 0;
      left: 0;
    }
  `}
`

const GlobalStyleWithContext = () => (
  <AppContext.Consumer>
    {({ focusStyleOn }) => {
      return <GlobalStyle showFocus={focusStyleOn} />
    }}
  </AppContext.Consumer>
)

export default GlobalStyleWithContext
