import React from 'react'

export function LeftArrow({ size = 11, color = '#000', ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.458.958l-4.5 4.5 4.5 4.5m4.5-4.5H1.601h8.357z"
        stroke={color}
        strokeOpacity={0.3}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function UpArrow({ size = 18, color = '#000', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeOpacity={0.3}
      strokeWidth={1.5}
      strokeLinecap="square"
      strokeLinejoin="round"
    >
      <path d="M7 17l9.2-9.2M17 17V7H7" />
    </svg>
  )
}

export const Arrow = ({ size = 14, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="square"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M5 12h13M12 5l7 7-7 7" />
  </svg>
)

export const Menu = ({ color = '#545454', ...props }) => (
  <svg width={15} height={11} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke={color}>
      <line y1="0.5" x2="15" y2="0.5" />
      <line y1="5.5" x2="15" y2="5.5" />
      <line y1="10.5" x2="15" y2="10.5" />
    </g>
  </svg>
)

export const X = ({ size = 13, color = '#fff', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 1.25l-11 11M1 1.25l11 11" stroke={color} strokeLinecap="square" strokeLinejoin="round" />
  </svg>
)
