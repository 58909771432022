import React, { Fragment, useLayoutEffect, useRef, useCallback } from 'react'

import Button from '../components/common/Button'
import MediaQueryDebug from '../components/common/MediaQueryDebug'
import { LeftArrow, UpArrow, Menu, X } from '../components/common/Icons'
import Text from '../components/common/Text'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useAppContext } from '../context/AppContext'
import { useToggleOnce } from '../hooks/useToggle'
import breakpoints from '../constants/breakpoints'
import { transform } from 'framer-motion'

const isClient = () => typeof window !== 'undefined'
const HEIGHT = 90
const HEIGHT_SM = 70
const HEIGHT_COMPACT = 55

const Navigation = ({ backLabel, fixed, isIndex, title: siteTitle, titleShort, links, light }) => {
  const {
    menu: { menuOpen, toggleMenu },
  } = useAppContext()
  const [on, toggle] = useToggleOnce()
  const ref = useRef()
  const refEmpty = useRef()
  const rule = `1px solid var(${light ? '--color-ruleLight' : '--color-rule'})`

  useLayoutEffect(() => {
    toggle()
  }, [toggle])

  const onScroll = useCallback(() => {
    if (!isClient()) return false

    const width = Math.max(document.documentElement.clientWidth, window.innerWidth) || 0
    const isMedium = width >= breakpoints.md
    const y = document.body.scrollTop || document.documentElement.scrollTop
    const inputRange = [30, 0]
    const navHeight = isMedium ? HEIGHT : HEIGHT_SM
    const outputRange = [HEIGHT_COMPACT, navHeight]
    let output = transform(y, inputRange, outputRange)
    ref.current.style.height = rem(output)
    if (refEmpty.current) refEmpty.current.style.height = rem(output)
  }, [ref])

  useLayoutEffect(() => {
    if (isClient()) {
      window.addEventListener('scroll', onScroll)
      onScroll()
    }
    return () => {
      if (isClient()) window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <>
      <MediaQueryDebug />
      <Nav
        ref={ref}
        role="navigation"
        fixed={fixed}
        light={light}
        css={{
          borderBottom: rule,
        }}
      >
        <Content>
          {!isIndex && (
            <Button to="/" preset={light ? 'primaryLight' : 'primary'} aria-label="Back to index">
              <LeftArrow style={{ marginRight: rem(6) }} /> {backLabel}
            </Button>
          )}

          {isIndex && (
            <>
              <MenuButton
                preset="menuButton"
                icon
                onClick={toggleMenu}
                aria-label={menuOpen ? 'Close menu' : 'Open menu'}
              >
                {menuOpen ? <X /> : <Menu />}
              </MenuButton>

              {on &&
                !!links &&
                links.map(({ title, externalUrl, page }, i) => {
                  const slug = page?.slug
                  const link = slug
                    ? { to: `/${slug}` }
                    : {
                        href: externalUrl,
                        target: '_blank',
                        rel: 'noreferrer',
                      }
                  return (
                    <Fragment key={title}>
                      <LinkButton {...link} preset="primary">
                        {title}
                        {!slug && <UpArrow />}
                      </LinkButton>
                      {i === 0 && (
                        <>
                          <TitleSmall size="sm" right light={light} color={light ? '#fff' : 'primary'}>
                            {documentToReactComponents(titleShort.json)}
                          </TitleSmall>
                          <TitleLarge size="sm">{documentToReactComponents(siteTitle.json)}</TitleLarge>
                        </>
                      )}
                    </Fragment>
                  )
                })}
            </>
          )}
        </Content>
      </Nav>
      {!!fixed && <NavSpacer ref={refEmpty} />}
    </>
  )
}

export default Navigation

const navStyle = ({ theme }) => css`
  width: 100%;
  height: ${rem(HEIGHT_SM)};

  ${theme.media.min('md')} {
    height: ${rem(HEIGHT)};
  }

  transition: 0.1s height;
`

const NavSpacer = styled.div`
  ${navStyle};
`

const Nav = styled.nav`
  ${({ fixed, theme }) => css`
    ${navStyle};

    display: flex;
    justify-content: center;
    align-items: center;

    ${fixed &&
      css`
        position: fixed;
        background: #fff;
        opacity: 0.95;
        backdrop-filter: blur(3px);
        z-index: 9999;
      `};
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    width: 100%;

    ${theme.media.min('sm')} {
      padding: 0 ${rem(10)};
    }

    ${theme.media.min('md')} {
      padding: 0 ${rem(20)};
    }
  `}
`

const SiteTitleText = styled(Text)`
  ${({ light, theme }) => css`
    padding: ${rem(10)} 0;

    b {
      color: ${theme.color[light ? 'secondaryLight' : 'secondary']};
      font-weight: 500;
      font-style: normal;
    }

    i {
      display: inline-flex;
      transform: translateY(-1px);
      font-style: normal;
    }

    ${theme.media.max('sm')} {
      margin-right: ${rem(20)};
    }
  `}
`

const TitleSmall = styled(SiteTitleText)`
  ${({ theme }) => css`
    ${theme.media.min('md')} {
      display: none;
    }
  `}
`

const TitleLarge = styled(SiteTitleText)`
  ${({ theme }) => css`
    ${theme.media.max('sm')} {
      display: none;
    }
  `}
`

const MenuButton = styled(Button)`
  ${({ theme }) => css`
    display: flex;

    ${theme.media.min('md')} {
      display: none;
    }

    height: ${rem(35)};
    padding: 0 ${rem(20)};
  `}
`

const LinkButton = styled(Button)`
  ${({ theme }) => css`
    display: none;

    ${theme.media.min('md')} {
      display: flex;
    }
  `}
`
