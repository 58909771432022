import { css } from 'styled-components/macro'
import { rem } from 'polished'
import fontSizes from '../../constants/type'

const primaryHover = css`
  opacity: 0.5;
  transition: opacity 0.3s ease-out;
`

const primaryStyle = ({ disabled, theme }) => css`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  padding: ${rem(10)} ${theme.space(2)};

  cursor: pointer;

  transition: opacity 0.4s ease-in;

  @media (hover: hover) {
    &:hover {
      ${primaryHover};
    }
  }

  ${disabled &&
    css`
      background-color: transparent;
    `};
`

const primary = {
  text: {
    bold: true,
    nowrap: true,
    size: fontSizes.sm,
    color: 'primary',
    uppercase: true,
  },
  disabledText: {
    //color: 'rgba(255, 255, 255, 0.5)',
  },
  style: primaryStyle,
  touchStyle: primaryHover,
}

const primaryLight = {
  ...primary,
  text: {
    ...primary.text,
    color: '#fff',
  },
}

const social = {
  ...primary,
  text: {
    ...primary.text,
    uppercase: false,
    condensedMedium: true,
  },
  style: css`
    ${primaryStyle};
    padding-left: 0;
    padding-right: 0;

    img {
      margin-right: ${rem(10)};
    }
  `,
}

const socialLight = {
  ...social,
  text: {
    ...social.text,
    color: '#fff',
  },
}

const logo = {
  ...primary,
  text: {
    ...primary.text,
    bold: false,
  },
  style: css`
    ${primaryStyle};
    padding-top: 0;
    padding-bottom: 0;
  `,
}

const menu = {
  text: {
    ...primary.text,
    color: '#fff',
    bold: true,
    size: fontSizes.lg,
    uppercase: true,
    center: false,
  },
  style: ({ disabled, theme }) => css`
    padding: ${rem(5)} ${rem(20)};

    ${theme.media.min('sm')} {
      padding: ${rem(5)} ${rem(30)};
    }

    cursor: pointer;

    transition: opacity 0.4s ease-in;

    @media (hover: hover) {
      &:hover {
        ${primaryHover};
      }
    }

    ${disabled &&
      css`
        background-color: transparent;
      `};
  `,
}

const image = {
  style: ({ disabled, theme }) => css`
    transition: filter 0.3s ease-in;

    @media (hover: hover) {
      &:hover {
        filter: brightness(0.85) contrast(1.1);
        //opacity: 0.9;
        transition: filter 0.3s ease-out;
      }
    }
  `,
}

export const presets = {
  image,
  primary,
  primaryLight,
  social,
  socialLight,
  logo,
  menu,
  menuButton: {
    touchStyle: primaryHover,
    style: () => css`
      cursor: pointer;

      transform: translateY(2px);

      transition: opacity 0.4s ease-in;

      @media (hover: hover) {
        &:hover {
          ${primaryHover};
        }
      }
    `,
  },
}

export const presetKeys = Object.keys(presets)
