import React from 'react'

import Button from '../components/common/Button'

function About({ socialLinks, light }) {
  return (
    <ul>
      {socialLinks.map(({ email, title, svgImage, svgImageLight, url }) => {
        const href = email ? `mailto:${email}` : url
        const size = svgImage?.file?.details?.image || {}
        return (
          <li key={title}>
            <Button href={href} target="_blank" preset={light ? 'socialLight' : 'social'}>
              <img {...size} src={light ? svgImageLight?.file?.url : svgImage?.file?.url} alt={title} />
              {title}
            </Button>
          </li>
        )
      })}
    </ul>
  )
}

export default About
