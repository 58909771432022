import { SANS_SERIF, CONDENSED } from '../../constants/type'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components/macro'
import { get, isNumber } from 'lodash'
import { rem, em } from 'polished'
import fontSizes from '../../constants/type'
import { motion } from 'framer-motion'

export const sizes = fontSizes
const fontKeys = Object.keys(fontSizes)

export const setFontSize = val => {
  return isNumber(val)
    ? css`
        font-size: ${rem(val)};
      `
    : css`
        font-size: var(${`--font-size-${val}`});
      `
}

export const textStyle = ({
  bold,
  condensed,
  condensedBold,
  condensedMedium,
  center,
  color,
  ellipsis,
  inline,
  lineHeight,
  minWidth,
  minWidthSize,
  nowrap,
  opacity,
  right,
  justify,
  selectNone,
  size,
  spacing,
  theme,
  underline,
  uppercase,
  white,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => css`
  position: relative;

  font-family: ${SANS_SERIF};
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  ${setFontSize(size)};

  text-align: left;
  color: ${theme.color.primary};
  margin: 0;

  z-index: 1;

  a,
  a:visited,
  a:active {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  strong {
    font-weight: 500;
  }

  /* Override */

  ${bold &&
    css`
      font-weight: 500;
    `};

 ${condensed &&
   css`
     font-family: ${CONDENSED};
   `};

   ${condensedMedium &&
     css`
       font-family: ${CONDENSED};
       font-weight: 500;
     `};

   ${condensedBold &&
     css`
       font-family: ${CONDENSED};
       font-weight: 700;
     `};

  ${inline &&
    css`
      display: inline;
    `};

  ${center &&
    css`
      text-align: center;
    `};

  ${color &&
    css`
      color: ${get(theme.color, color) || color};
    `};


  ${ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};

  ${nowrap &&
    css`
      white-space: nowrap;
    `};

  ${opacity &&
    css`
      opacity: ${opacity};
    `};

  ${lineHeight &&
    css`
      line-height: ${lineHeight};
    `};

  ${right &&
    css`
      text-align: right;
    `};

  ${justify &&
    css`
      text-align: justify;
    `};

  ${selectNone &&
    css`
      user-select: none;
    `};

  ${spacing &&
    css`
      letter-spacing: ${!isNaN(spacing) ? rem(spacing) : spacing};
    `};

  ${underline &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: ${rem(1)};
        background-color: currentColor;
      }
    `};

  ${uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${white &&
    css`
      color: #fff;
    `};

  /* Media queries */

  ${xs &&
    css`
      ${theme.media.min('xs')} {
        ${setFontSize(xs)};
      }
    `}

  ${sm &&
    css`
      ${theme.media.min('sm')} {
        ${setFontSize(sm)};
      }
    `}

    ${md &&
      css`
        ${theme.media.min('md')} {
          ${setFontSize(md)};
        }
      `}

    ${lg &&
      css`
        ${theme.media.min('lg')} {
          ${setFontSize(lg)};
        }
      `}

    ${xl &&
      css`
        ${theme.media.min('xl')} {
          ${setFontSize(xl)};
        }
      `}

    ${xxl &&
      css`
        ${theme.media.min('xxl')} {
          ${setFontSize(xxl)};
        }
      `}

    ${minWidth &&
      css`
        @media (min-width: ${em(minWidth)}) {
          ${minWidthSize && setFontSize(minWidthSize)};
        }
      `};

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const Text = styled.div`
  ${textStyle};
`

Text.propTypes = {
  bold: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  condensed: PropTypes.bool,
  condensedMedium: PropTypes.bool,
  condensedBold: PropTypes.bool,
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  inline: PropTypes.bool,
  lineHeight: PropTypes.number,
  linkColor: PropTypes.string,
  minWidth: PropTypes.number,
  minWidthSize: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  nowrap: PropTypes.bool,
  right: PropTypes.bool,
  selectNone: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // rem || 'normal'
  theme: PropTypes.object,
  white: PropTypes.bool,
  uppercase: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
}

Text.defaultProps = {
  lineHeightMultiplier: 1,
  size: fontSizes.md,
}

export default Text

export const MotionText = styled(motion.div)`
  ${textStyle};
`

MotionText.propTypes = Text.propTypes
MotionText.defaultProps = Text.defaultProps
