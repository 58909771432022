import styled, { css } from 'styled-components/macro'
const IS_DEV = process.env.NODE_ENV === 'development'

const MediaQueryDebug = styled.div`
  ${({ theme }) => css`
    z-index: 9999999;

    ${!IS_DEV &&
    css`
      display: none;
    `};

    &:after {
      position: absolute;
      top: 0;
      left: 0;

      font-family: -apple-system, sans-serif;
      font-size: 0.6em;
      line-height: 1.5em;
      letter-spacing: 0.1em;
      font-weight: 300;

      background: rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 1);
      padding: 0 3px;

      content: 'XXS';

      ${theme.media.min('xs')} {
        content: 'XS';
      }

      ${theme.media.min('sm')} {
        content: 'SM';
      }

      ${theme.media.min('md')} {
        content: 'M';
      }

      ${theme.media.min('lg')} {
        content: 'LG';
      }

      ${theme.media.min('xl')} {
        content: 'XL';
      }

      ${theme.media.min('xxl')} {
        content: 'XXL';
      }
    }
  `};
`

export default MediaQueryDebug
