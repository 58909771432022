const breakpoints = {
  xxs: 0,
  xs: 321,
  sm: 376,
  md: 577,
  lg: 993,
  xl: 1200,
  xxl: 1580,
}

export default breakpoints
