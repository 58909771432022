import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import { Link } from 'gatsby'

import styled, { css } from 'styled-components/macro'
import { get } from 'lodash'
import useTouch from '../../hooks/useTouch'
//import { event as trackEvent } from '../../utils/tracking'

import { presets, presetKeys } from './ButtonPresets'

const Button = forwardRef(
  (
    {
      children,
      disabled,
      disabledText,
      href,
      hoverStyle,
      icon,
      onClick,
      preset,
      rel,
      style,
      target,
      textStyle,
      to,
      type,
      //tracking,
      ...props
    },
    ref
  ) => {
    const [isTouched, bind] = useTouch()
    const Wrapper = to ? StyledLink : StyledButton
    const presetStyles = get(presets, `${preset}`) || undefined
    const presetText = get(presetStyles, 'text') || undefined
    const touchStyle = get(presetStyles, 'touchStyle') || undefined

    const disabledTextStyle = disabled ? get(presets, `${preset}.disabledText`) : {}

    const content = icon ? (
      children
    ) : (
      <Text
        center
        color="currentColor"
        hoverColor="currentColor"
        style={textStyle}
        {...presetText}
        {...disabledTextStyle}
      >
        {children}
      </Text>
    )

    const handleClick = useCallback(
      e => {
        if (onClick) onClick()

        // GA track
        // if (tracking) {
        //   trackEvent(tracking.event, {
        //     category: 'click',
        //     ...omit(tracking, 'event'),
        //   })
        // }
      },
      [onClick]
    )

    const sharedProps = {
      ...props,
      ...bind,
      onClick: handleClick,
      style,
      preset,
      touched: isTouched ? touchStyle : undefined,
    }

    return !!href ? (
      <StyledExternalLink {...sharedProps} ref={ref} href={href} target={target} rel={rel}>
        {content}
      </StyledExternalLink>
    ) : (
      <Wrapper {...sharedProps} ref={ref} to={to} type={type}>
        {content}
      </Wrapper>
    )
  }
)

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  inline: PropTypes.bool,
  icon: PropTypes.bool,
  rel: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  preset: PropTypes.oneOfType([PropTypes.oneOf(presetKeys)]),
  style: PropTypes.object,
  textStyle: PropTypes.object,
  tracking: PropTypes.PropTypes.shape({
    event: PropTypes.string.isRequired,
    category: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
}

Button.defaultProps = {
  type: 'button',
}

export default Button

const wrapperStyle = ({ color, disabled, inline, touched, preset, theme }) => css`
  ${theme.mixin.buttonReset};

  ${() => {
    const presetStyle = get(presets, `${preset}.style`)
    if (!!presetStyle) return presetStyle
  }};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  pointer-events: auto;

  > * {
    user-select: none;
  }

  ${touched};

  ${color &&
    css`
      color: ${color};
    `};

  ${disabled &&
    css`
      pointer-events: none !important;
      opacity: 0.5;
    `};

  ${inline &&
    css`
      display: inline-flex;
    `};
`

const StyledButton = styled.button`
  ${wrapperStyle};
`

const StyledLink = styled(Link)`
  ${wrapperStyle};
`

const StyledExternalLink = styled.a`
  ${wrapperStyle};
`
