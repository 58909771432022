import { em, rem } from 'polished'
import { css, keyframes } from 'styled-components/macro'

import color from '../constants/color'
import layout from '../constants/layout'
import fontSizes from '../constants/type'
import breakpoints from '../constants/breakpoints'

import Media from './media'
const media = Media(breakpoints)

const spacingBase = 10
export const space = (multiplier = 2) => rem(spacingBase * multiplier)

const fadeInFrames = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    animation-delay: 0;
  }
`

const fadeIn = (delay = 0, time = 0.7) => css`
  animation: ${fadeInFrames} ${time}s ease-out ${delay}s both;
`

const buttonReset = css`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
`

const flex = css`
  display: flex;
`

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const flexCenterColumn = css`
  ${flexCenter};
  flex-direction: column;
`

const fill = css`
  width: 100%;
  height: 100%;
`

const fillAbsolute = css`
  ${fill};
  position: absolute;
  top: 0;
  left: 0;
`

const absoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const afterFocus = ({ showFocus }) => css`
  ${showFocus &&
    css`
      &:focus {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid blue;
          box-shadow: inset 0 0 5px ${color.blue};
          background-color: transparent;
          pointer-events: none;
        }
      }
    `};
`

export const lessThan = (size = 'md') => css`
  @media not all and (max-width: ${em(breakpoints[size])}) {
    display: none !important;
  }
`

export const greaterThan = (size = 'md') => css`
  @media not all and (min-width: ${em(breakpoints[size])}) {
    display: none !important;
  }
`

export const only = (size = 'md') => css`
  @media not all and (min-width: 0px) and (${em(breakpoints[size])}) {
    display: none !important;
  }
`

export const greaterThanOrEqual = (size = 'md') => css`
  @media not all and (${em(breakpoints[size])}) {
    display: none !important;
  }
`

export const between = (start = 'md', end = 'lg') => css`
  @media not all and (${em(breakpoints[start])}) and (${em(breakpoints[end])}) {
    display: none !important;
  }
`

const theme = {
  breakpoints,
  color,
  fontSizes,
  layout,
  media, // media queries
  mediaHelp: {
    only,
    between,
    lessThan,
    greaterThan,
    greaterThanOrEqual,
  },
  space,
  mixin: {
    absoluteCenter,
    afterFocus,
    buttonReset,
    fadeIn,
    flex,
    flexCenter,
    flexCenterColumn,
    fill,
    fillAbsolute,
  },
}

export default theme
