import React, { useLayoutEffect } from 'react'

const isClient = () => typeof window !== 'undefined'

function useLockBodyScroll(scrollToTop) {
  useLayoutEffect(() => {
    if (isClient()) {
      document.body.style.overflow = 'hidden'
      if (scrollToTop) window.scrollTo(0, 0)
    }

    return () => {
      if (isClient()) document.body.style.overflow = 'auto'
    }
  }, [scrollToTop])
}

export function LockBodyScroll({ scrollToTop }) {
  useLockBodyScroll(scrollToTop)
  return <></>
}

export default useLockBodyScroll
