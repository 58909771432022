const colors = {
  background: '#fff',
  primary: '#545454',
  secondary: 'rgba(0,0,0,0.3)',
  secondaryLight: 'rgba(255,255,255,0.3)',
  rule: 'rgba(0,0,0,0.1)',
  ruleLight: 'rgba(255,255,255,0.1)',
}

export default colors
