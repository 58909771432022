const backup = 'Helvetica Neue, -apple-system, BlinkMacSystemFont, Arial, sans-serif'

export const AKTIV = 'aktiv-grotesk'
export const AKTIV_COND = 'aktiv-grotesk-condensed'

export const HELVETICA = backup
export const SANS_SERIF = `${AKTIV}, ${backup}`
export const CONDENSED = `${AKTIV_COND}, ${backup}`

// px
const fontSizes = {
  sm: 13,
  md: 14,
  lg: 24,
}

export const sizeKeys = Object.keys(fontSizes)
export const sizeValues = Object.values(fontSizes)

export default fontSizes
