import React from 'react'
import Container from '../components/Container'
import Navigation from '../components/Navigation'
import Menu from '../components/Menu'

import AppProvider from '../context/AppContext'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from '../style/global'

import theme from '../style/theme'

const style = 'background: #222; color: #ecb1ff; padding: 15px;'
console.log('%c Design & Development: Zero Style https://zero.style ', style)

function Layout({ data, children, location }) {
  const isIndex = location.pathname === '/'
  const nav = {
    isIndex,
    links: data?.contentfulSite?.links,
    title: data?.contentfulSite?.siteTitle,
    titleShort: data?.contentfulSite?.siteTitleShort,
    backLabel: data?.contentfulSite?.backToIndexLabel,
    socialLinks: data?.contentfulAbout?.socialLinks,
  }

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container>
          <Navigation fixed location={location} {...nav} />
          {children}
        </Container>
        <Menu {...nav} />
      </ThemeProvider>
    </AppProvider>
  )
}

export default Layout
