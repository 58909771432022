import { useMemo, useState } from 'react'

function useTouch() {
  const [isTouched, setTouched] = useState(false)

  const bind = useMemo(
    () => ({
      onTouchStart: e => setTouched(true),
      onTouchEnd: e => setTouched(false),
    }),
    []
  )

  return [isTouched, bind]
}

export default useTouch
