import { useCallback, useState, useRef } from 'react'
import useTimeout from './useTimeout'

const useToggle = (initial = false) => {
  const [on, setToggle] = useState(initial)

  const reset = useCallback(() => setToggle(initial), [initial])
  const toggle = useCallback(() => setToggle(prev => !prev), [])
  const toggleOn = useCallback(() => setToggle(true), [])
  const toggleOff = useCallback(() => setToggle(false), [])

  return [on, { set: setToggle, reset, toggle, toggleOn, toggleOff }]
}

export default useToggle

export function useToggleOnce(initial = false) {
  const [on, { toggle }] = useToggle(initial)
  const hasRun = useRef(false)
  const callback = useCallback(() => {
    if (hasRun.current) return
    hasRun.current = true
    toggle()
  }, [toggle])
  return [on, callback]
}

export function useToggleTimeout(initial = false, time = 1000) {
  const [on, { toggle }] = useToggle(initial)
  useTimeout(toggle, time)
  return [on]
}

export function useToggleOnceTimeout(initial = false, time = 1000) {
  const [on, toggle] = useToggleOnce(initial)
  useTimeout(toggle, time)
  return [on]
}

export function useToggleOnceThenTimeout(initial = false, time = 1000) {
  const [timeoutOn, { toggle: toggleTimeout }] = useToggle()
  const [on, toggle] = useToggleOnce(initial)
  useTimeout(toggle, time, timeoutOn)
  return [on, { timeoutOn, toggle: toggleTimeout }]
}
