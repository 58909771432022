import React from 'react'
import { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import useListenForAccessibility from '../hooks/useListenForAccessibility'
import breakpoints from '../constants/breakpoints'
import { useMediaQuery } from 'react-responsive'
import useToggle from '../hooks/useToggle'

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [menuOpen, { toggle: toggleMenu }] = useToggle()

  // Listen for key events to turn on focus styles
  const focusStyleOn = useListenForAccessibility()

  // Breakpoints
  const isMedium = useMediaQuery({ minWidth: breakpoints.md })
  const isLarge = useMediaQuery({ minWidth: breakpoints.lg })
  const isXLarge = useMediaQuery({ minWidth: breakpoints.xl })
  const isXXLarge = useMediaQuery({ minWidth: breakpoints.xxl })

  const value = useMemo(
    () => ({
      focusStyleOn,
      menu: {
        menuOpen,
        toggleMenu,
      },
      media: {
        isMedium,
        isLarge,
        isXLarge,
        isXXLarge,
      },
    }),
    [focusStyleOn, isMedium, isLarge, isXLarge, isXXLarge, menuOpen, toggleMenu]
  )
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
